import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import PublicLayout from "components/Layout/Layout";

const Home = lazy(() => import("./pages/Home"));
const Register = lazy(() => import("./pages/BasicRegisteration"));
const Login = lazy(() => import("./pages/Login"));
const Forgot = lazy(() => import("./pages/Forgot"));
const JobPage = lazy(() => import("./pages/Job"));
const AdPage = lazy(() => import("./pages/Ad"));
const UserDashboard = lazy(() => import("./pages/UserDashboard"));
const CompanyRegistration = lazy(() => import("./pages/CompanyRegistration"));

const CompanySettings = lazy(() => import("./pages/UserDashboard/Settings"));
const socialSettings = lazy(() => import("./pages/UserDashboard/Social"));
const CompanyContact = lazy(() =>
  import("./pages/UserDashboard/ContactInformation")
);
const HoursSettings = lazy(() => import("./pages/UserDashboard/OpeningHours"));
const VideosSettings = lazy(() => import("./pages/UserDashboard/Videos"));
const ManageEvents = lazy(() => import("./pages/UserDashboard/Events"));
const ManageJobs = lazy(() => import("./pages/UserDashboard/Jobs"));
const ManageServices = lazy(() => import("./pages/UserDashboard/Services"));
const ManageAbout = lazy(() => import("./pages/UserDashboard/About"));
const MangePriceList = lazy(() => import("./pages/UserDashboard/PriceList"));
const ManageMenuList = lazy(() => import("./pages/UserDashboard/MenuList"));
const ManagePartners = lazy(() => import("./pages/UserDashboard/Partners"));
const ManageFavourite = lazy(() => import("./pages/UserDashboard/Favourite"));
const ManageAd = lazy(() => import("./pages/UserDashboard/Ad"));
// Admin Dashboard
const ManageCompanies = lazy(() => import("./pages/AdminDashboard/ManageCompanies"));
const Industries = lazy(() => import("./pages/AdminDashboard/Industries"));
const Recommendations = lazy(() => import("./pages/AdminDashboard/Recommendations"));
const Monitoring = lazy(() => import("./pages/AdminDashboard/Monitoring"));
const CompanyAds = lazy(() => import("./pages/AdminDashboard/CompanyAds"));
const Profile = lazy(() => import("./pages/Profile"));
const Search = lazy(() => import("./pages/Search"));

function App() {
  return (
    <Router>
      <PublicLayout>
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <Route exact index path="/" component={Home} />
            <Route path="/home" component={Home} />
            <Route path="/register" component={Register} />
            <Route path="/login" component={Login} />
            <Route path="/onboarding" component={CompanyRegistration} />
            <Route path="/forgot" component={Forgot} />
            <Route path="/ad" component={AdPage} />
            <Route path="/job/:id" component={JobPage} />
            <Route path="/company/edit/masterdata" component={UserDashboard} />
            <Route path="/company/edit/settings" component={CompanySettings} />
            <Route
              path="/company/edit/contactinformation"
              component={CompanyContact}
            />
            <Route
              path="/company/edit/socialsettings"
              component={socialSettings}
            />
            <Route
              path="/company/edit/hourssettings"
              component={HoursSettings}
            />
            <Route
              path="/company/edit/videosettings"
              component={VideosSettings}
            />
            <Route path="/company/edit/manageevents" component={ManageEvents} />
            <Route path="/company/edit/jobs" component={ManageJobs} />
            <Route path="/company/edit/services" component={ManageServices} />
            <Route path="/company/edit/about" component={ManageAbout} />
            <Route path="/company/edit/pricelist" component={MangePriceList} />
            <Route path="/company/edit/menu" component={ManageMenuList} />
            <Route path="/company/edit/partners" component={ManagePartners} />
            <Route path="/company/edit/favourite" component={ManageFavourite} />
            <Route path="/company/edit/ad" component={ManageAd} />
            <Route path="/admin/edit/companies" component={ManageCompanies} />
            <Route path="/admin/edit/industries" component={Industries} />
            <Route path="/admin/edit/monitoring" component={Monitoring} />
            <Route path="/admin/edit/recommendations" component={Recommendations} />
            <Route path="/admin/edit/ads" component={CompanyAds} />
            <Route path="/profile" component={Profile} />
            <Route path="/search" component={Search} />
          </Switch>
        </Suspense>
      </PublicLayout>
    </Router>
  );
}

export default App;
