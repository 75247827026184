import React from "react";
import instagram from "assets/images/instagram.png";
import facebook from "assets/images/facebook.png";
import LinkedIn from "assets/images/LinkedIn.png";
import LogoSvg from "assets/svgs/logoSvg";
import "./Footer.scss";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
   
    <>
     <div className="main-footer">
      <div className="section-one col">
        <div className="logo">
          <LogoSvg />
        </div>
        <div className="description">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vitae
          elit nulla augue morbi. Lorem ipsum dolor sit amet, consectetur
          adipiscing elit. Nunc vitae elit nulla augue morbi.
        </div>
        <div className="follow">
          <div className="footerHeading">FOLLOW</div>
          <div className="socialShare">
            <span>
              <a
                href="https://www.facebook.com/pages/category/Video-Creator/Cheggl-GmbH-108536584202586/"
                target={"_blank"}
              >
                <img src={facebook} alt="facebook" />
              </a>
            </span>
            <span>
              <a
                href="https://de.linkedin.com/company/cheggl-gmbh"
                target={"_blank"}
              >
                <img src={LinkedIn} alt="LinkedIn" />
              </a>
            </span>
            <span>
              <a
                href="https://www.instagram.com/cheggl_videoagentur/"
                target={"_blank"}
              >
                <img src={instagram} alt="instagram" />
              </a>
            </span>
          </div>
        </div>
      </div>
      <div className="section-two col">
        <div className="heading">SERVICE</div>
        <div className="footer-menu">
          <a href="#">Order video</a>
          <a href="#">How it Works</a>
          <a href="#">Satisfied costumers</a>
          <a href="#">Registration</a>
          <a href="#">Login</a>
          <a href="#">Advertise on Cheggl</a>
          <a href="#">Subscribe to our Newsletter</a>
          <a href="#">FAQ</a>
          <a href="#">Contact</a>
          <a href="#">Jobs</a>
        </div>
      </div>
      <div className="section-three col">
        <div className="heading">LEGAL</div>
        <div className="footer-menu">
          <a href="#">License terms</a>
          <a href="#">Policy</a>
          <a href="#">Terms</a>
        </div>
      </div>
      <div className="section-four col">
        <div className="heading">NEWSLETTER</div>
        <div className="description">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vitae
          elit nulla augue morbi. Lorem ipsum dolor sit amet, consectetur
          adipiscing elit. Nunc vitae elit nulla augue morbi.
        </div>
        <hr />
        <div className="importantLinks">
          <a href="#">HOME</a>
          <span className="dot"></span>
          <a href="#">BLOG</a>
          <span className="dot"></span>
          <a href="#">LISTING</a>
          <span className="dot"></span>
          <a href="#">CONTACTS</a>
        </div>
      </div>
     
    </div>
     <hr />
      <div className='copyrights'>© 2019-2023 All Rights Reserved.</div>
    </>
  );
};

export default Footer;
