import * as React from "react";
const BurgerMenu = ({ fill }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={26} height={20} fill="none">
    <path
      stroke={fill ? fill : "#000"}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2.712}
      d="M2 2.154h21.693M2 10.29h21.693M2 18.424h21.693"
    />
  </svg>
);
export default BurgerMenu;
