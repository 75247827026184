import React from "react";

const SearchIcon = ({ fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0419 9.67839C11.9428 8.51641 12.3674 7.05471 12.2293 5.59066C12.0912 4.1266 11.4008 2.77018 10.2984 1.79733C9.19609 0.824487 7.76469 0.308299 6.29542 0.353783C4.82615 0.399266 3.42938 1.003 2.38929 2.04217C1.34828 3.08206 0.742809 4.47992 0.696257 5.95089C0.649705 7.42186 1.16558 8.85524 2.13874 9.95892C3.11191 11.0626 4.46914 11.7535 5.93384 11.8909C7.39853 12.0283 8.86048 11.6017 10.0217 10.6982L10.0528 10.7308L13.1176 13.7975C13.1848 13.8647 13.2645 13.918 13.3522 13.9543C13.4399 13.9907 13.5339 14.0094 13.6288 14.0094C13.7237 14.0094 13.8177 13.9907 13.9054 13.9543C13.9932 13.918 14.0728 13.8647 14.14 13.7975C14.2071 13.7304 14.2603 13.6507 14.2967 13.5629C14.333 13.4752 14.3517 13.3811 14.3517 13.2862C14.3517 13.1912 14.333 13.0972 14.2967 13.0094C14.2603 12.9217 14.2071 12.842 14.14 12.7748L11.0744 9.70875C11.0639 9.69833 11.053 9.68821 11.0419 9.67839ZM9.542 3.06491C9.94993 3.46641 10.2744 3.94472 10.4966 4.47227C10.7188 4.99982 10.8344 5.56617 10.8367 6.13864C10.839 6.71111 10.7281 7.27838 10.5101 7.80773C10.2922 8.33707 9.97171 8.81801 9.56707 9.22282C9.16243 9.62762 8.68167 9.94827 8.15254 10.1663C7.62341 10.3843 7.05637 10.4953 6.48412 10.493C5.91188 10.4906 5.34576 10.375 4.81842 10.1527C4.29108 9.93038 3.81295 9.60583 3.41162 9.19774C2.60959 8.38222 2.16217 7.28268 2.16683 6.13864C2.17149 4.9946 2.62784 3.89875 3.43648 3.08978C4.24512 2.28082 5.34054 1.82429 6.48412 1.81963C7.62771 1.81497 8.7268 2.26256 9.542 3.06491Z"
        fill={fill ? fill : "#7C7C7C"}
      />
    </svg>
  );
};

export default SearchIcon;
