import React from "react";

const HeartSvg = ({ fill, height, width }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : "14"}
      height={height ? height : "13"}
      viewBox="0 0 14 13"
      fill="none"
    >
      <path
        d="M13.4472 2.90418C14.0701 4.7426 13.5488 6.61333 12.4503 8.02282C11.7252 8.97864 10.8586 9.80242 9.97258 10.5226C9.15747 11.2474 7.33335 12.6815 6.84983 12.7213C6.42247 12.6433 5.94294 12.1817 5.60367 11.9441C3.69718 10.5597 1.64491 8.87619 0.60432 7.0355C-0.268137 5.26836 -0.269738 3.08262 1.08813 1.7278C2.84881 0.211566 5.50323 0.507841 6.84982 2.09192C7.21146 1.64378 7.65617 1.29133 8.18395 1.03458C10.3235 0.218782 12.5493 1.05006 13.4472 2.90418Z"
        fill={fill}
      />
    </svg>
  );
};

export default HeartSvg;
