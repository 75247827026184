import React from 'react'

import './Search.scss'
const SearchInput = ({
    handleKeyPress,
    name = '',
    icon,
    placeholder,
    classIcon,
    value,
    handleChange,
    isError,
    error,
    type = 'search'
  }) => {
    return (
      <div className="search-input">
        {icon && classIcon === 'left' && <span className="search-icon">{icon}</span>}
        <input
          type={type}
          name={name}
          placeholder={placeholder || 'Search'}
          value={value}
          onChange={handleChange}
          onKeyPress={handleKeyPress || (() => {})}
        />
        {icon && classIcon === 'right' && <span className="search-icon">{icon}</span>}
        {isError && <span className="error">{error}</span>}
      </div>
    );
  };
  export default SearchInput;