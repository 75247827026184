import React from "react";
import "./Header.scss";
import BurgerMenu from "assets/svgs/burgerMenu";
import cheggl from "assets/images/cheggl.png";
import whiteLogo from "assets/images/WhiteLogo.png";
import SearchInput from "components/Search/Search";
import SearchIcon from "assets/svgs/search";
import Button from "components/Button/Button";
import ButtonArrow from "assets/svgs/buttonArrow";
import UserSvg from "assets/svgs/userSvg";
import HeartSvg from "assets/svgs/heartSvg";
import { Link } from "react-router-dom";

const Header = ({ homeHeader, isSticky }) => {
  return (
    <div
      className={`${homeHeader ? "home-header" : "main-header"} ${
        isSticky ? "sticky" : ""
      }`}
    >
      <div className="left">
        <div className="burgerMenu">
          <BurgerMenu fill={homeHeader ? (isSticky ? "" : "#fff") : "#000000"} />
        </div>
        <div className="logo">
          <Link to="/home">
            {" "}
            <img
              src={homeHeader ? (isSticky ? cheggl : whiteLogo) : cheggl}
              alt="cheggl Logo"
            />
          </Link>
        </div>
      </div>
      <div className="right">
        <div className="headerSearch">
          <SearchInput classIcon="left" icon={<SearchIcon />} />
        </div>
        <div className="divider"></div>
        <div className="companyButton">
          <Button
            iconPosition="right"
            text="For Companies"
            icon={<ButtonArrow />}
          />
        </div>
        <div className="user-icon">
          <UserSvg  fill={homeHeader ? (isSticky ? "" : "#fff") : "#5315B5"} />
        </div>
        <div className="wishlist-icon">
          <HeartSvg width="27" height="27"  fill={homeHeader ? (isSticky ? "#5315b5" : "#fff") : "#5315B5"} />
        </div>
      </div>
    </div>
  );
};

export default Header;
